import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import {featuredTextSerializers} from '../../core/serializers'
import {displayPublishedDate} from '../../core/utils'
import ContentBlock from '../cards/ContentBlock'
import {
  BasicResourceProjectionSchema,
  HeroProjectionSchema,
} from '../resources/schemas'
import {getResourceSlug} from '../resources/utils'
import {getFieldFilter} from './utils'

const HeroPropsSchema = z.object({
  field: z.string(),
  headline: z.string().optional(),
  heroResource: z.union([BasicResourceProjectionSchema, HeroProjectionSchema]),
  resources: z.array(BasicResourceProjectionSchema),
})

export default function Hero({
  field,
  headline = 'New Content',
  heroResource,
  resources,
}: z.infer<typeof HeroPropsSchema>) {
  const fieldFilter = getFieldFilter(field)

  if (!heroResource || !resources) {
    return null
  }

  return (
    <section className="py-0">
      <div className="container" style={{borderTop: '3px double #e9ecef'}}>
        <div className="row py-4">
          <div className="col-lg-7">
            <ContentBlock
              image={heroResource.featuredImage}
              imageWidth={960}
              imagePriority
              title={
                <h1 className="h3">
                  {'titleOverride' in heroResource &&
                  heroResource.titleOverride ? (
                    <PortableText
                      value={heroResource.titleOverride}
                      components={featuredTextSerializers}
                    />
                  ) : (
                    heroResource.title
                  )}
                </h1>
              }
              body={heroResource.featuredText}
              link={
                'url' in heroResource
                  ? (heroResource.url as string)
                  : getResourceSlug(heroResource.slug)
              }
              flexDirection="column"
              useVideoEmbed={
                'url' in heroResource ? heroResource.isVideo : false
              }
            />
          </div>

          <div className="col-lg-5 mt-3 mt-lg-0">
            <h6 className="mb-3">{headline}</h6>
            {resources.map((resource) => (
              <ContentBlock
                key={resource._id}
                image={resource.featuredImage}
                imageWidth={160}
                heading={resource.category}
                subfooter={displayPublishedDate(resource.publishedAt)}
                title={
                  resource.titleOverride ? (
                    <h6 className="mb-n2">
                      <PortableText
                        value={resource.titleOverride}
                        components={featuredTextSerializers}
                      />
                    </h6>
                  ) : (
                    <h6 className="mb-1">{resource.title}</h6>
                  )
                }
                link={getResourceSlug(resource.slug)}
                flexDirection="row"
              />
            ))}
            <div className="d-flex justify-content-end">
              <a
                href={`/resources/${fieldFilter ? `?${fieldFilter}` : ''}`}
                className="btn btn-outline-primary"
              >
                See All Content &raquo;
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
