import {faChevronRight} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'
import qs from 'qs'
import {z} from 'zod'

import {featuredTextSerializers} from '../../core/serializers'
import {displayAuthorLinks, displayPublishedDate} from '../../core/utils'
import styles from '../../styles/TopicList.module.scss'
import ContentBlock from '../cards/ContentBlock'
import {getResourceSlug} from '../resources/utils'
import {GetIndexPageQueryDataSchema} from './types'
import {getFieldFilter} from './utils'

const TopicListPropsSchema = z.object({
  headline: z.string().optional(),
  topics: GetIndexPageQueryDataSchema.shape.indexPage.shape.topics,
  field: z.string(),
  resources: GetIndexPageQueryDataSchema.shape.resources.shape.recentResources,
})

export default function TopicList({
  headline = 'Topics',
  topics,
  field,
  resources,
}: z.infer<typeof TopicListPropsSchema>) {
  if (!topics || !topics.length || !resources) {
    return null
  }
  const fieldFilter = getFieldFilter(field)

  return (
    <section className="pt-1">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-4">
            <h5>{headline}</h5>
            <hr />
            <ul className={`${styles['topic-list']} mb-4 mb-lg-0`}>
              {topics.map((topic) => {
                if (!topic?.qs) {
                  return
                }
                const query = qs.stringify(topic.qs, {
                  addQueryPrefix: true,
                  encodeValuesOnly: true,
                  format: 'RFC1738',
                })
                return (
                  <li key={topic._id}>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <a
                      href={`/resources/${query}${
                        fieldFilter ? `&${fieldFilter}` : ''
                      }`}
                    >
                      {topic.name}
                    </a>
                  </li>
                )
              })}
            </ul>
            <div className="mt-3">
              <a
                href={`/resources/${fieldFilter ? `?${fieldFilter}` : ''}`}
                className="d-none d-lg-inline-block btn btn-sm btn-outline-primary"
              >
                Browse All Topics &raquo;
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-8">
            {resources.map((resource) => (
              <ContentBlock
                key={resource._id}
                containerClass="pb-3 mb-3"
                image={resource.featuredImage}
                hideMobileImage
                imageWidth={160}
                heading={resource.category.toUpperCase()}
                footer={displayAuthorLinks(resource.authors)}
                subfooter={displayPublishedDate(resource.publishedAt)}
                title={
                  resource.titleOverride ? (
                    <h5 className="mb-n2">
                      <PortableText
                        value={resource.titleOverride}
                        components={featuredTextSerializers}
                      />
                    </h5>
                  ) : (
                    <h5 className="mb-1">{resource.title}</h5>
                  )
                }
                link={getResourceSlug(resource.slug)}
                flexDirection="row"
                flexFooterDirection="row"
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
