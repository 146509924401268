import Image from 'next/legacy/image'

import {z} from 'zod'

import {GetFeaturedJobsDataSchema} from '../../core/api'

const FeaturedJobsPropsSchema = z.object({
  displayFeaturedJobs: z.boolean().optional(),
  jobs: GetFeaturedJobsDataSchema,
  headline: z.string().optional(),
})

export default function FeaturedJobs({
  displayFeaturedJobs,
  jobs,
  headline = 'Featured Jobs',
}: z.infer<typeof FeaturedJobsPropsSchema>) {
  if (!displayFeaturedJobs || !jobs || jobs.length === 0) {
    return null
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="d-flex align-items-center justify-content-between">
              <span className="h4">{headline}</span>
              <div>
                <a
                  href="https://jobs.eyesoneyecare.com/jobs/eyecare/?utm_campaign=index-featured-jobs"
                  className="btn btn-sm btn-primary me-1"
                >
                  Search Jobs
                </a>
                <a
                  href="https://jobs.eyesoneyecare.com/eyecare-hiring-products/?utm_campaign=index-featured-jobs"
                  className="btn btn-sm btn-outline-primary"
                >
                  Post a Job
                </a>
              </div>
            </div>
            <hr />
          </div>
          {jobs.map((job, i) => (
            <div
              key={i}
              className="col-12 col-lg-4 mb-2 mb-lg-0 position-relative"
            >
              <div className="d-flex h-100 p-3 index-job border rounded shadow-sm align-items-center">
                <div className="me-3">
                  <Image
                    src={job.image}
                    height={50}
                    width={50}
                    alt={job.title}
                    className="rounded"
                  />
                </div>
                <div>
                  <span className="mb-1">
                    <a
                      href={`${job.url}?utm_campaign=index-featured-jobs`}
                      className="stretched-link"
                    >
                      {job.title}
                    </a>
                  </span>
                  <p className="mb-0 text-small text-muted">{job.location}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
