import {z} from 'zod'

import SearchBar from '../forms/SearchBar'
import {GetIndexPageQueryDataSchema} from '../landing/types'
import {getFieldFilter, getResourceFeedQueryString} from '../landing/utils'

const NavLinksPropsSchema = z.object({
  field: z.string(),
  navLinks: GetIndexPageQueryDataSchema.shape.indexPage.shape.navLinks,
})

export default function NavLinks({
  field,
  navLinks,
}: z.infer<typeof NavLinksPropsSchema>) {
  const fieldFilter = getFieldFilter(field)

  return (
    <nav id="navLinks" className="d-none d-lg-block bg-white">
      <div className="container py-3">
        <div className="d-flex justify-content-between align-items-center">
          <SearchBar />
          {navLinks.map((link) => {
            return (
              <a
                key={link._key}
                href={`/resources/${getResourceFeedQueryString(
                  link.resourceFeedFilters,
                )}${fieldFilter ? `&${fieldFilter}` : ''}`}
              >
                {link.name}
              </a>
            )
          })}
        </div>
      </div>
    </nav>
  )
}
