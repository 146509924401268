import {z} from 'zod'

import {GetIndexPageQueryDataSchema} from '../landing/types'

const NavAlertPropsSchema = z.object({
  navAlert: GetIndexPageQueryDataSchema.shape.indexPage.shape.navAlert,
})

export default function NavAlert({
  navAlert,
}: z.infer<typeof NavAlertPropsSchema>) {
  return (
    <nav id="navAlert">
      <div className="container border-top py-3">
        <div className="d-flex">
          <div className="d-flex flex-column flex-md-row align-items-center">
            <span className="pulse alert-badge bg-danger rounded text-white px-2 me-md-2 mb-2 mb-md-0">
              {navAlert.alertBadgeText}
            </span>
            <span className="serif text-center text-md-start">
              <a
                href={navAlert.alertUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {navAlert.alertText} &raquo;
              </a>
            </span>
          </div>
        </div>
      </div>
    </nav>
  )
}
