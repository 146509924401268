import Link from 'next/link'

import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import {featuredTextSerializers} from '../../core/serializers'
import {GetIndexPageQueryDataSchema} from '../landing/types'
import SanityImage from '../sanity/SanityImage'

const NavResourcesPropsSchema = z.object({
  navResources: GetIndexPageQueryDataSchema.shape.indexPage.shape.navResources,
})

export default function NavResources({
  navResources,
}: z.infer<typeof NavResourcesPropsSchema>) {
  return (
    <nav id="navResources">
      <div className="container border-top py-3">
        <div className="row align-items-center">
          {navResources.map((resource, i) => (
            <div
              className="col-12 col-lg-4 resource mb-2 mb-lg-0"
              key={
                '_id' in resource
                  ? resource._id
                  : `navresources-${resource.title}-${i}`
              }
            >
              <div className="d-flex align-items-center position-relative">
                <div
                  className="d-flex align-items-center"
                  style={{minWidth: '96px'}}
                >
                  <SanityImage
                    className=""
                    src={resource.featuredImage}
                    alt={resource.title}
                    width={96}
                    height={54}
                    objectFit="fill"
                  />
                </div>
                <Link
                  href={
                    'url' in resource
                      ? resource.url
                      : `/resources/${resource.slug}/`
                  }
                  className="stretched-link ms-2"
                >
                  {'titleOverride' in resource && resource.titleOverride ? (
                    <div className="mb-0 paragraph-inherit-styles">
                      <PortableText
                        value={resource.titleOverride}
                        components={featuredTextSerializers}
                      />
                    </div>
                  ) : (
                    resource.title
                  )}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </nav>
  )
}
