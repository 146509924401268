import {z} from 'zod'

import {SanityImage} from '../../core/schemas'
import {CustomSponsorSchema, OrganizationSchema} from '../organizations/schemas'
import {
  BasicResourceProjectionSchema,
  ContentBuilderProjectionSchema,
  HeroProjectionSchema,
  SpotlightProjectionSchema,
} from '../resources/schemas'

export const GetUniqueAuthorsDataSchema = z.array(
  z.object({
    _id: z.string(),
    name: z.string(),
    profilePicture: SanityImage,
    slug: z.string(),
  }),
)

export const ResourceFeedFilterProjectionSchema = z.object({
  _id: z.string(),
  name: z.string(),
  qs: z.union([
    z.object({
      clinical_specialty: z.string(),
    }),
    z.object({category: z.string()}),
    z.object({tags: z.string()}),
  ]),
})
export const ResourceFeedFilterProjectionsSchema = z.array(
  ResourceFeedFilterProjectionSchema,
)

export const IndexPageNavLinkSchema = z.object({
  _key: z.string(),
  _type: z.string(),
  name: z.string(),
  resourceFeedFilters: ResourceFeedFilterProjectionsSchema,
})

export const GetIndexPageQueryDataSchema = z.object({
  indexPage: z.object({
    careerTopics: z.array(ResourceFeedFilterProjectionSchema),
    customAdCode: z.string().nullish(),
    customNavLogo: SanityImage.nullish(),
    clinicalTopics: z.array(ResourceFeedFilterProjectionSchema),
    displayFeaturedJobs: z.boolean(),
    featuredJobsTag: z.string(),
    hero: HeroProjectionSchema,
    heroResource: BasicResourceProjectionSchema,
    navAlert: z.object({
      showAlertBeacon: z.boolean(),
      alertText: z.string(),
      alertBadgeText: z.string(),
      alertUrl: z.string(),
    }),
    navLinks: z.array(IndexPageNavLinkSchema),
    navResources: z.array(
      z.union([BasicResourceProjectionSchema, ContentBuilderProjectionSchema]),
    ),
    sponsors: OrganizationSchema.array(),
    customSponsors: z
      .object({
        name: z.string(),
        sponsors: z.array(CustomSponsorSchema),
      })
      .nullish(),
    showSpotlight: z.boolean().nullish(),
    spotlightSponsorText: z.string().nullish(),
    spotlight: SpotlightProjectionSchema,
    spotlightResource: BasicResourceProjectionSchema,
    topics: z.array(ResourceFeedFilterProjectionSchema),
  }),
  resources: z.object({
    careerResources: z.array(BasicResourceProjectionSchema),
    clinicalResources: z.array(BasicResourceProjectionSchema),
    recentResources: z.array(BasicResourceProjectionSchema),
    sponsoredResources: z.array(BasicResourceProjectionSchema),
  }),
})
