import {CustomSponsors} from '../organizations/schemas'
import SanityImage from '../sanity/SanityImage'

export default function SponsorLogos({
  name,
  sponsors,
}: {
  name: string
  sponsors: CustomSponsors
}) {
  return (
    <div className="container mt-4 mt-lg-0">
      <h3 className="mb-1 text-center">{name}</h3>
      <div className="row justify-content-center align-items-center">
        {sponsors.map((sponsor) => (
          <div
            className={`${
              sponsors.length > 4 ? 'col-4' : 'col-3'
            } d-flex justify-content-center align-items-center`}
            key={sponsor.name}
          >
            <a href={sponsor.website} rel="noopener noreferrer" target="_blank">
              <SanityImage
                src={sponsor.image}
                alt={sponsor.name}
                width={140}
                height={100}
                className="rounded"
                objectFit={'contain'}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
