import groq from 'groq'
import {z} from 'zod'

import {backendClient} from '../../core/sanity'
import {
  basicResourceProjection,
  generalResourceQuery,
} from '../resources/queries'
import {GetUniqueAuthorsDataSchema} from './types'

export const getRoleFilters = (field: string) => {
  // Adds a particular role filter for the groq query based on `field`
  // e.g. `&& ("Optometrist" in roles[]->name || "Optician" in roles[]->name || "Optometric Technician" in roles[]->name )`
  let roles
  switch (field) {
    case 'Index':
      roles = [
        'Optometrist',
        'Optician',
        'Optometric Technician',
        'Ophthalmic Technician',
        'Ophthalmologist',
      ]
      break
    case 'Optometry':
      roles = [
        'Optometrist',
        'Optician',
        'Optometric Technician',
        'Ophthalmic Technician',
      ]
      break
    case 'Ophthalmology':
      roles = ['Ophthalmologist']
      break
    default:
      roles = [
        'Optometrist',
        'Optician',
        'Optometric Technician',
        'Ophthalmic Technician',
        'Ophthalmologist',
      ]
  }
  let queryString = `&& ("${roles[0]}" in roles `
  roles.slice(1).forEach((role) => {
    queryString += `|| "${role}" in roles `
  })
  queryString += ')'
  return queryString
}

export const getUniqueAuthors = async (roleFilterString: string) => {
  // get authors of the latest 100 resource pages
  const query = `*[
      _type == "resourcePage" 
      && !(_id in path("drafts.**"))
      && !(authors[]->email match "@covalentcreative.com")
      && !(authors[]->email match "@covalentcareers.com")
      && !(authors[]->email match "@eyesoneyecare.com")
      && authors[]->profilePicture != null
      ${roleFilterString}
    ] | order(publishedAt desc)[0...100].authors[]->{_id, name, profilePicture, "slug": slug.current}
    `
  const authors =
    await backendClient.fetch<z.infer<typeof GetUniqueAuthorsDataSchema>>(query)
  const uniqueAuthors = new Set()

  return authors
    .filter((author) => {
      if (!uniqueAuthors.has(author.name)) {
        uniqueAuthors.add(author.name)
        return true
      }
      return false
    })
    .slice(0, 12)
}

export const resourceFeedFilterProjection = groq`{
  _id,
  name,
  "qs": {
    _type == "clinicalSpecialty" => {
      "clinical_specialty": name
    },
    _type == "category" => {
      "category": name
    },
    _type == "tag" => {
      "tags": name
    }
  },
}`

export const indexPageGROQQuery = (field: string) => groq`*[
  _type == "indexPage" &&
  field == "${field}"
][0]{
  heroResource->${basicResourceProjection},
  hero,
  customAdCode,
  customNavLogo{
    ...,
    _type,
    asset->{...}
  },
  customSponsors->{
    name,
    sponsors[]->{name, image, website}
  },
  showSpotlight,
  spotlightResource->${basicResourceProjection},
  spotlightSponsorText,
  spotlight,
  navLinks[]{
    ...,
    resourceFeedFilters[]->${resourceFeedFilterProjection}
  },
  "navResources": navResources[]{
    _type == 'reference' => @->${basicResourceProjection},
    _type != 'reference' => @,
  },
  navAlert,
  topics[]->${resourceFeedFilterProjection},
  clinicalTopics[]->${resourceFeedFilterProjection},
  careerTopics[]->${resourceFeedFilterProjection},
  displayFeaturedJobs,
  featuredJobsTag
}`

export const getIndexPageQuery = (field: string, roleFilterString: string) => {
  // Gets groq query used to fetch IndexPage from Sanity
  //
  // Queries for...
  // ->indexPage using field to filter for the correct indexPage document depending on the current page (/index, /optometry, /ophthalmology)
  // ->resources filtering based on roles and clinicalSpecialty per the resourceFilters
  // ->and authors filtering based on authors of latest resourcePages with roleFilters, who are also not CC authors
  const resourceFilters =
    field === 'Interventional Mindset'
      ? {
          recent: `&& "interventional mindset" in tags[]->name`,
          clinical: `&& clinicalSpecialty._ref == "clinical_specialty-Glaucoma" && "interventional mindset" in tags[]->name`,
          careers: `&& clinicalSpecialty._ref == "clinical_specialty-RefractiveSurgery" && "interventional mindset" in tags[]->name`,
          sponsored: `&& clinicalSpecialty._ref == "clinical_specialty-OcularSurface" && "interventional mindset" in tags[]->name`,
        }
      : {
          recent: `${roleFilterString}`,
          clinical: `&& clinicalSpecialty._ref != "clinical_specialty-Non-Clinical" ${roleFilterString}`,
          careers: `&& clinicalSpecialty._ref == "clinical_specialty-Non-Clinical" ${roleFilterString}`,
          sponsored: `&& defined(sponsor) && !(disclosure in ["advertising", "sponsorAdvertising"]) ${roleFilterString}`,
        }

  return groq`{
    "indexPage": ${indexPageGROQQuery(field)},
    "resources": {
      "recentResources": ${generalResourceQuery(resourceFilters.recent, 9)},
      "clinicalResources": ${generalResourceQuery(resourceFilters.clinical, 6)},
      "careerResources":${generalResourceQuery(resourceFilters.careers, 6)},
      "sponsoredResources": ${generalResourceQuery(
        resourceFilters.sponsored,
        5,
      )},
    }
  }`
}
