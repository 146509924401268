import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import {featuredTextSerializers} from '../../core/serializers'
import {displayAuthorLinks, displayPublishedDate} from '../../core/utils'
import {BasicResourceProjectionSchema} from '../resources/schemas'
import {getResourceSlug} from '../resources/utils'
import ContentBlock from './ContentBlock'

const CardRowProps = z.object({
  headline: z.string(),
  resources: z.array(BasicResourceProjectionSchema),
})

export default function CardRow({
  resources,
  headline,
}: z.infer<typeof CardRowProps>) {
  if (!resources) {
    return null
  }

  return (
    <section className="pb-0">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-2">
            <span className="h4">{headline}</span>
            <hr />
          </div>
          {resources.map((resource) => (
            <div className="col-xs-12 col-sm-4" key={resource._id}>
              <ContentBlock
                image={resource.featuredImage}
                heading={resource.category.toUpperCase()}
                body={resource.featuredText}
                footer={displayAuthorLinks(resource.authors)}
                subfooter={displayPublishedDate(resource.publishedAt)}
                title={
                  resource.titleOverride ? (
                    <h5 className="mb-n2">
                      <PortableText
                        value={resource.titleOverride}
                        components={featuredTextSerializers}
                      />
                    </h5>
                  ) : (
                    <h5 className="mb-1">{resource.title}</h5>
                  )
                }
                link={getResourceSlug(resource.slug)}
                flexDirection="column"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
